if (typeof gsap !== 'undefined') {
    if ($(".scrollTrigger_1").length !== 0) {
        const icons1 = gsap.timeline({
            scrollTrigger: {
                trigger: ".scrollTrigger_1",
                start: "top",
                end: "bottom",
                scrub: true,
            }
        });

        const icons2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".scrollTrigger_1",
                start: "top",
                end: "bottom",
                scrub: true,
            }
        });
        const icons3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".scrollTrigger_1",
                start: "top",
                end: "bottom",
                scrub: true,
            }
        });
        const icons4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".scrollTrigger_1",
                start: "top",
                end: "bottom",
                scrub: true,
            }
        });
        const icons5 = gsap.timeline({
            scrollTrigger: {
                trigger: ".scrollTrigger_1",
                start: "top",
                end: "bottom",
                scrub: true,
            }
        });
        const icons6 = gsap.timeline({
            scrollTrigger: {
                trigger: ".scrollTrigger_1",
                start: "top",
                end: "bottom",
                scrub: true,
            }
        });
        const icons7 = gsap.timeline({
            scrollTrigger: {
                trigger: ".scrollTrigger_1",
                start: "top",
                end: "bottom",
                scrub: true,
            }
        });

        icons1.to(".animate__icon_1", {
            top: '150%',
            left: '45%',
            opacity: 0,
            scale: 0.1,
            duration: 1
        })
        icons2.to(".animate__icon_2", {
            top: '150%',
            left: '45%',
            opacity: 0,
            scale: 0.4,
            duration: 1
        })
        icons3.to(".animate__icon_3", {
            top: '150%',
            left: '45%',
            opacity: 0,
            scale: 0.4,
            duration: 1
        })
        icons4.to(".animate__icon_4", {
            top: '150%',
            left: '45%',
            opacity: 0,
            scale: 0.4,
            duration: 1
        })
        icons5.to(".animate__icon_5", {
            top: '150%',
            left: '45%',
            opacity: 0,
            scale: 0.4,
            duration: 1
        })
        icons6.to(".animate__icon_6", {
            top: '150%',
            left: '45%',
            opacity: 0,
            scale: 0.4,
            duration: 1
        })
        icons7.to(".animate__icon_7", {
            top: '150%',
            left: '45%',
            opacity: 0,
            scale: 0.4,
            duration: 1
        })


        $(document).scroll(function () {
            $(".animate__icon_1").css("animate-name", "1")



        })
    }
}