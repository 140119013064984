window.onload = (function () {
    if (typeof gsap !== 'undefined') {
        gsap.registerPlugin(ScrollTrigger);

        class ScrubControlledAnimation {
            constructor(animationWrapper, animation, start, end) {
                this.DOM = {
                    animationWrapper: animationWrapper,
                    animation: animation,
                    states: {},
                };
                this.animationWrapper = document.querySelector(this.DOM.animationWrapper);
                this.animation = document.querySelector(this.DOM.animation);
                this.start = start
                this.end = end

                this.init();
            }

            init() {
                if (this.animation) {
                    this.scrubAnimation();
                }
            }

            scrubAnimation() {
                const scrubAnimationOptions = {
                    container: this.animation,
                    renderer: "svg",
                    loop: false,
                    autoplay: false,
                    path: this.animation.getAttribute("data-animation-source"),
                };

                let scrubAnimation = lottie.loadAnimation(scrubAnimationOptions);
                scrubAnimation.addEventListener("DOMLoaded", () => {
                    this.loadAnimation(scrubAnimation);
                });
            }

            loadAnimation(animation) {
                const scrubAnimationTimeline = gsap.timeline({}).to({
                        frame: 0,
                    }, {
                        duration: 5,
                        frame: animation.totalFrames - 1,
                        onUpdate: function () {
                            animation.goToAndStop(
                                Math.round(this.targets()[0].frame),
                                true
                            );
                        },
                    },
                    "start"
                );

                ScrollTrigger.create({
                    trigger: this.animationWrapper,
                    animation: scrubAnimationTimeline,
                    // markers: true,
                    start: this.start,
                    end: this.end,
                    scrub: 0.4,
                });
            }
        }

        new ScrubControlledAnimation(
            ".analytics__animate",
            ".first-animate",
            "0",
            "9500"
        );
        new ScrubControlledAnimation(
            ".report__animate",
            ".second__animate",
            "0",
            "5000"
        );
    }
})();




// first and second animate
console.log();
$(document).scroll(function () {
    let s_top = $(document).scrollTop();
    
    if ($(".analytics").length !== 0) {

        let firstAnimate = $(".analytics").offset().top;
        let secondAnimate = $(".report").offset().top;
        let thirdAnimate = $(".work").offset().top;

        if (s_top < thirdAnimate) {
            $(".animate__icon_1").css("top", '22%')
            $(".animate__icon_1").css("left", '10%')

            $(".animate__icon_2").css("top", '0%')
            $(".animate__icon_2").css("left", '30%')

            $(".animate__icon_3").css("top", '25%')
            $(".animate__icon_3").css("left", '45%')

            $(".animate__icon_4").css("top", '20%')
            $(".animate__icon_4").css("left", '60%')

            $(".animate__icon_5").css("top", '5%')
            $(".animate__icon_5").css("left", '80%')

            $(".animate__icon_6").css("top", '70%')
            $(".animate__icon_6").css("left", '33%')

            $(".animate__icon_7").css("top", '75%')
            $(".animate__icon_7").css("left", '70%')
        }

        if (s_top > firstAnimate - 50) {
            $(".analytics").addClass("active")
            $("#parallax").addClass("active")
        } else {
            $(".analytics").removeClass("active")
            $("#parallax").removeClass("active")
        }

        if (s_top > secondAnimate - 100) {
            $(".report").addClass("active")
            $(".analytics").removeClass("active")
            $("#parallax").removeClass("active")

        } else {
            $(".report").removeClass("active")
        }

        if (s_top > thirdAnimate - 100) {
            $(".report").removeClass("active")
            $(".work").addClass("active")
        } else {
            $(".work").removeClass("active")
        }
        if ($(".work").css('height') <= '3500px') {
            if (s_top > thirdAnimate + 2650) {
                $(".work").addClass("end")
            } else {
                $(".work").removeClass("end")
            }
        } else {
            if (s_top > thirdAnimate + 3000) {
                $(".work").addClass("end")
            } else {
                $(".work").removeClass("end")
            }
        }
    }

});

// last animate
window.onload = function () {
    if (typeof gsap !== 'undefined') {
        const textAnimate = gsap.timeline({
            scrollTrigger: {
                trigger: ".work",
                start: "-8%",
                end: "70%",
                scrub: true,
            }
        });
        textAnimate.to(".work__text_1", {
            yPercent: 0,
            left: '0%',
            opacity: 1,
            duration: 100
        })
        textAnimate.to(".work__text_1", {
            yPercent: 0,
            left: '-40%',
            opacity: 0,
            duration: 100
        })
        textAnimate.to(".work__text_2", {
            yPercent: 0,
            right: '0%',
            opacity: 1,
            duration: 100
        })
        textAnimate.to(".work__text_2", {
            yPercent: 0,
            right: '-50%',
            opacity: 0,
            duration: 100
        })
        textAnimate.to(".work__text_3", {
            top: '100%',
            xPercent: 0,
            opacity: 1,
            duration: 100
        })
    }
}